.tekst{

    font-size: 2rem;
    color: white;
    
}
.imageNameCard{
    background-color: green;
    height: 4rem;
    width: 15rem;
    padding: 1rem 0;
    border-radius: 1rem;
    margin: 0 auto;
}