.containerStyle {
    padding: 0 150px; /* Padding sa leve i desne strane na većim ekranima */
    position: relative;
    overflow: hidden; 
}

.backgroundTop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 35%;
    background-color: rgb(14,55,71); /* Prvi div u jednoj boji */
    z-index: -2; /* Promenjeno u z-index: 0; */
}

.backgroundBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65%;
    background-color: #1d4e5b;  /* Drugi div u drugoj boji */
    z-index: -2; /* Promenjeno u z-index: 0; */
}

.image {
    width: 120%;
    height: auto;
    display: block;
    position: relative;
    z-index: -1; /* Promenjeno u z-index: 1; */
    border-radius: 3%;
    margin: 0 auto;
    transform: translateX(-8%); 
}

/* Media upit za male ekrane */
@media only screen and (max-width: 768px) {
    .containerStyle {
        padding: 0 10px; /* Promena padding-a za male ekrane */
    }
}

@media (min-width: 768px) {
    .containerStyle {
        max-width: 1500px; 
        margin: 0 auto;
    }
}
