.kontejnerwraper {
    background-color: #1d4e5b;
    max-width: 1500px; 
    margin: 0 auto;
    padding: 0% 7%;
}

.kontejner{
    padding: 4rem 0rem;
    background-color: #1d4e5b;
    color: whitesmoke;
    max-width: 1500px;
    margin: 0 auto;
    align-items: center;
    text-align: left;
    
}
.naslov{
    text-align: center;
    font-weight: 700;
}
.tekst{
    padding: 1rem 6rem 1rem;
    text-align: center;
    font-weight: 300;
    font-size: 1.3rem;
}

.slika{
    align-self: flex-start;
    width: 800px;
    height: 500px;
    padding: 0rem 5rem;
    border-radius: 5%;
}
.dugme{
    margin: 8rem 0;
}
.buttondiv{
    text-align: center;
    padding-top: 3rem;
}
@media only screen and (max-width: 768px) {
    
    .slika {
        width: 370px;
        height: 220px;
    }
    .tekst{
        padding: 2rem 0;
        text-align: center;
    }
    .red{
        text-align: center;
        align-items: center;
    }
    .cardrow{
        padding: 0;
        align-items: center;
        text-align: center;
        order: 1;
    }
    .kontejner{
        align-items: center;
        text-align: center;
        padding: 5rem 1rem;
    }
    .imagecolumn{
        order: 2;
        padding-bottom: 2rem;
    }
    .buttondiv{
        text-align: center;
        padding-top: 0rem;
    }
    .naslov{
        text-align: left;
        margin-left: 10px;
    }
    .tekst{
        text-align: left;
        margin-left: 10px;
    }

  }