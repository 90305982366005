/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Custom CSS Variables */
:root {
    --mainColor: #0d3340; /* Promenjena boja pozadine navbara */
    --mainColorLight: #5767aa;
    --secondaryColor: #DB2B39;
    --textColor: #eee;
}

/* General Header Styles */
.header-container {
    height: 80px;
    background-color: var(--mainColor);
    color: var(--textColor);
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.logo-link {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.logo {
    height: 40px;
}

nav {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}

nav a {
    margin: 0 20px;
    color: var(--textColor);
    text-decoration: none;
}

nav a:hover {
    color: var(--secondaryColor);
}

/* Hamburger Button Styles */
.nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
    color: #24c311; /* Promenjena boja hamburger menija */
}

/* Mobile Menu Styles */
.mobile-menu {
    display: none;
}

.mobile-menu.open {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--mainColor);
    z-index: 1500;
}

.mobile-menu nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-menu nav a {
    margin: 10px;
    font-size: 1.5rem;
    color: var(--textColor);
    text-decoration: none;
}

/* Dodajemo stilove za mobilne uređaje */
@media only screen and (max-width: 992px) {
    .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    .header-container {
        justify-content: space-between;
        position: fixed;
        width: 100%;
    }

    .logo-container {
        justify-content: flex-start; /* Promenili smo ovo da bi logo bio poravnat na levo */
    }

    .d-lg-none {
        display: flex !important;
    }

    .d-lg-flex {
        display: none !important;
    }

    .mobile-nav {
        display: none;
    }

    .desktop-nav {
        display: none;
    }

    .signup-button {
        display: none;
    }
}


nav a:hover {
    color: green; /* Promenite boju hover efekta na zelenu */
}
.navbar-link-active {
    color: #00C613;
}