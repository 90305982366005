.card {
    border-radius: 12px;
    padding: 1rem 1rem;
    margin: 16px 0px;
    text-align: left;
    background-color: #0e3747;
    height: auto;
    justify-content: center;
  gap: 30px;
  margin-top: 20px;
  max-width: 420px;
  
  
  }

  .kart{
    width: 350px;
  }
  .header {
    font-size: 20px;
    font-weight: 500;
    color:#00c613;
    padding-bottom: 0.3rem;
  }
  .text {
    margin-bottom: 10px;
    color: rgb(170, 166, 166);
    line-height: 1.2rem;
  }
  .image{
    height: 70px;
    
  }
  .imagecol {
    padding: auto ;
    margin: auto 0;
  }
  .dugme{
    padding:0.3rem 1.5rem;
    margin: 0;
  }
  @media only screen and (max-width: 768px) {
    
   .card{
    
    height: 200px;
   }
  }

  .row{
    min-height: 75px;
  }