.custombutton{
    background-color: #00c613;
    color: #0e3747;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .custom-button:hover {
    background-color: #1af330;
  }

  .custombuttonsize{
    background-color: #00c613;
    color: #0e3747;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    min-width: 180px;
  }

  @media only screen and (max-width: 768px) {
    .custombuttonsize{
      background-color: #00c613;
      color: #0e3747;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      max-width: 140px;
      height: auto;
      
    }
  
   }
  