.firstCol{
    padding: 4rem 4rem;
    background-color: #1d4e5b;
    border-radius: 20px;
    color: white;
    
}
.subtitle{
    color: rgb(170, 166, 166);
}

.secondCol{
 height: 340px;
 width: 505px;
 background-color: #1d4e5b;
 border-radius: 20px;
 margin:auto;
 color: white;
}
.formDiv{
 margin: auto;
 padding: 1rem;
}
.list{
    border: 1px solid rgb(170, 166, 166);
    border-radius: 20px;
    padding: 1rem;
}
.custom-placeholder::placeholder {
    color: white; /* Replace with your desired color */
  }
  .ebook-highlight-part {
    color: black;
    background: linear-gradient(to right, #adf77f, #7ef435); /* Example background color */
    padding: 0.1rem 0.3rem; /* Padding around the text */
    border-radius: 8px; /* Rounded corners */
  }
@media(max-width: 768px) {
    
   .firstCol{
    padding: 1.5rem;
    border-radius: 50px;
   }
    .secondCol{
        height: 400px;
        width: 400px;
        border-radius: 20px;
        margin: 2rem auto ;
    }
    .formDiv{
        padding: 2rem;
        text-align: center;
    }
  }

  @media (min-width: 1800px) and (max-width: 3000px) {
    .firstCol{
        padding: 2rem 5rem 10rem  19rem   ;
    }
    .mainTitle{
        padding:  2rem 3rem 8rem 1rem;
        line-height: 1.3;
    }
    .subtitle{
        padding-bottom: 2rem;
    }
  }
