.komponenta{
    text-align: center;
    padding: 9% 9% 8%;
    background-color:#1d4e5b ;
    color: whitesmoke;
    max-width: 1500px;
    margin: 0 auto;
}

.tekst1{
    padding: 0 10rem;
    font-size: 2.5rem;
}
.tekst2{
    padding: 1rem 5rem;
    color: rgb(170, 166, 166);
}
.kartice{
    padding-left: 1rem;
    padding-right: 1rem;
    
    
}

.cardcontainer {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
  }
    

@media only screen and (max-width: 768px) {
    
    .tekst1{
        padding: 0;
        text-align: center;
        font-size: 2rem;
    }

    .tekst2{
        padding: 0;
        text-align: center;
    }
    .komponenta{
        padding: 4rem 0rem;
    }
    .kartice{
        padding: 0;
        margin: 0;
    }
    .tekst1{
        text-align: left;
        margin-left: 20px;
    }
    .tekst2{
        text-align: left;
        margin-left: 20px;
    }
  }

  