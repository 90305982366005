.kontejner{
    padding: 4rem 8rem;
    color: whitesmoke;
    background-color: #1d4e5b;
    max-width: 1500px; 
    margin: 0 auto;
}


.ikonica{
    width: 2rem;
    padding-right: 5px;
}
.slika{
    width: 80%;
    height: auto;
    border-radius: 5%;
}
.prvidiv{
    padding-right: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.prednaslov{
    font-weight: 400;
    color:#00c613;
    line-height: 2rem;
    font-size: 1.4rem;
}
.naslov{
    font-weight: 700;
    font-size: 2rem;
    margin-top: 10px;
    margin-bottom: 30px;
    
}
.podnaslov{
    color:#c4cacd;
    font-weight: 400;
}

.wraprow {
    align-items: center;
}

@media only screen and (max-width: 768px) {
     .kontejner{
        padding: 2rem 1rem;
        align-items: center;
        text-align: left;
     }
     .slika{
        width: 280px;
        height: 180px;
        border-radius: 5%;
        margin-top: 2rem;
    }
    .prvidiv{
        padding-right: 0;
        order: 2;
    }
    .mestoslike{
        text-align: center;
    }
    .drugidiv{
        order: 1;
    }
    .naslov{
        font-size: 2rem;
        line-height: 2rem;
    }

    }
