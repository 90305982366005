
/* Card.module.css */

.cardContainer {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.card {
  border: 1px solid rgb(0,198,19);
  border-radius: 15px;
  padding: 20px;
  font-size: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 350px;
  text-align: center;
  color: white;
}

.card:hover {
  background-color: #f0f0f0;
  color: black;
}


