/* CoFButton.css */
.custom-button {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  background-color: transparent;
  transition: color 0.3s ease;
}

.custom-button:hover {
  color: #00FF00; /* Zelena boja teksta na hover */
  background-color: transparent
}
