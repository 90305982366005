.logo{
    width: 7%;
    padding-bottom: 20px;
    height: auto;
  }

.cta{
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
    max-width: 1500px; 
    margin: 0 auto;
}



@media (max-width: 767px) {
    .logo{
        width: 20%;
        height: auto;
      }
  }
