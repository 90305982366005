.recentPosts {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  font-family: 'Switzer', sans-serif;
  padding: 5px;

}

.postCard {
  background-color: #fff;
  /* Pozadina kartice */
  border-radius: 10px;
  /* Zaobljeni uglovi kartice */
  padding: 15px;
  /* Unutrašnje margine kartice */
  margin-bottom: 20px;
  /* Razmak između kartica */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Senka za karticu */
  width: 100%;
  /* Kartice će zauzeti punu širinu roditelja */
  max-width: 350px;
  /* Maksimalna širina kartice */
  min-height: 180px;
  /* Minimalna visina kartice */
  display: flex;
  /* Koristimo flexbox za raspored sadržaja */
  flex-direction: column;
  /* Postavka sadržaja u kolonu */
  overflow: hidden;
  /* Sakrij prelivajući sadržaj */
  text-decoration: none;
  /* Uklanja podcrtavanje linka */
  color: inherit;
  /* Nasleđuje boju teksta iz roditelja */
}

@media (min-width: 1500px) {
  .postCard {
    max-width: 405px;
  }


}

.postCardTitle {
  font-size: 1.2rem;
  /* Veća veličina naslova */
  margin: 0;
  /* Uklanjanje margine */
  margin-bottom: 10px;
  /* Razmak ispod naslova */
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.cardAuthor,
.cardDate {
  margin: 1;
  /* Uklanja podrazumevane margine */
  font-size: 14px;
  font-weight: 500;
  color: rgb(152, 161, 172);

}

.verticalLine {
  height: 20px;
  /* Visina vertikalne linije */
  width: 1px;
  /* Širina vertikalne linije */
  background-color: #ccc;
  /* Boja vertikalne linije */
  margin: 0 10px;
  /* Razmak između linije i teksta */
}

.postCardContent {
  flex: 1;
  /* Omogućava da div zauzme preostali prostor */
  overflow: hidden;
  /* Sakrij prelivajući sadržaj */
  text-overflow: ellipsis;
  /* Dodaje tri tačke za prelivajući tekst */
  display: -webkit-box;
  /* Za višestruke linije teksta */
  -webkit-line-clamp: 3;
  /* Broj linija koje će se prikazati pre skraćivanja */
  -webkit-box-orient: vertical;
  /* Vertikalno orijentisanje kutije */
  margin-bottom: 10px;
  /* Razmak ispod teksta */
  font-size: 16px;

  color: rgb(86, 111, 143);
  line-height: 24px;
  font-weight: 400;
}

.cardFooter {
  display: flex;
  /* Koristi Flexbox za raspored sadržaja */
  /* justify-content: space-between; Raspoređuje sadržaj na dva kraja */
  align-items: center;
  /* Poravnava sadržaj vertikalno u sredini */
  margin-top: auto;
  /* Pomera footer na dno kartice */
  font-size: 0.9rem;
  /* Smanjuje veličinu fonta ako je potrebno */
  padding-top: 2rem;
}

.cardAuthor,
.cardDate {
  margin: 0;
  /* Uklanja podrazumevane margine */
  font-family: 'Switzer', sans-serif;
}

.cardCategory {
  color: blue;
  padding-bottom: 1.5rem;
  font-weight: 600;
  size: 14px;
  line-height: 20px;
}