@media (min-width: 768px) {
  .logo{
    width: 7%;
    padding-bottom: 20px;
    height: auto;
  }
  .cta{
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    max-width: 1500px; 
    margin: 0 auto;
  }
  }
  
  @media (max-width: 767px) {
    .logo{
      width: 20%;
      height: auto;
    }
    .cta{
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
      
    }
  }

  
  

  