.card {
  border: '';
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem;
  text-align: left;
  background-color: #1d4e5b;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.card:hover {
  background-color: #2c6975; /* Change background color on hover */
}

.header {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 4px;
}

.text {
  margin-bottom: 4px;
  padding-bottom: 0.5rem;
}

.active {
  background-color: #0e3747; /* Change background color when active */
  border: '';
  border-radius: 8px;
  padding: 1rem;
  padding-left: 3rem;
  margin: 1rem;
  text-align: left;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
