html,
body,
#root {
  overflow-x: hidden;
  

}



body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Red Hat Display", sans-serif !important; 
  font-optical-sizing: auto !important;
  font-style: normal !important;
}

code {
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}





@media (min-width: 992px) {
  .container {
    /* max-width: 960px; */
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}