

.komponenta{
    text-align: center;
    padding: 5rem 1rem;
    color: whitesmoke;
    max-width: 1500px;
}
.naslov{
    color:rgb(0,198,19);
    text-transform: uppercase;
}
.podnaslov{
    padding-bottom: 3rem;
    padding-top: 1rem;
    font-weight: 300;
}

.komentari{
    padding: 0 12rem;
}
.res100{
    max-width: 1500px; 
    margin: 0 auto;
}

@media only screen and (max-width: 768px) {
    .komentari{
        padding: 1rem 1rem;
    }

    .kolonakomentari{
        padding-bottom: 1rem;
    }
  }

  @media (min-width: 768px) {
    
    .komponenta{
      margin: 0 auto;
    }
    
  
  }

  @media (max-width: 767px) {
    .naslov{
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        
    }
    .podnaslov{
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
    }
    .komponenta{
        text-align: center;
        padding: 5rem 1rem;
        color: whitesmoke;
        padding-top: 30px;
        padding-bottom: 30px;
    }
   
  }
