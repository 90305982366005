.categoryNav {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.categoryNavButton {
  flex: 1;
  padding: 10px;
  background-color: #1D4E5B;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.categoryNavButtonActive {
  background-color: #0D3B4E;
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1D4E5B; /* Primer pozadinske boje */
}

.searchWrapper {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.searchInput {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  padding-right: 1rem;
}

.searchIcon {
  margin-left: -30px;
  color: #aaa;
}
.title {
  font-size: 24px;
  margin: 0;
  font-family: 'Switzer', sans-serif;
  color:#fff;
  padding-left: 0.1rem;
  
}
.postCards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.postCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px; /* Defaultna maksimalna širina kartice za 100% */
  min-height: 180px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  font-family: 'Switzer', sans-serif;
}

/* Kada je zoom 150%, kartica će biti manja (320px max width) */
@media (min-width: 1500px) {
  .postCard {
    max-width: 375px;
  }

  .title {
    font-size: 24px;
    margin: 0;
    font-family: 'Switzer', sans-serif;
    color:#fff;
    padding-left: 0.1rem;
    
  }

  .searchWrapper {
    display: flex;
    align-items: center;
    padding-right: 1rem;
  }
}

.postCardTitle {
  font-size: 1.2rem; /* Veća veličina naslova */
  margin: 0; /* Uklanjanje margine */
  margin-bottom: 10px; /* Razmak ispod naslova */
  font-size: 18px;
  font-weight: 600;
  color: rgb(33, 56, 86);
  line-height: 25px;
  
}

.postCardContent {
  flex: 1; /* Omogućava da div zauzme preostali prostor */
  overflow: hidden; /* Sakrij prelivajući sadržaj */
  text-overflow: ellipsis; /* Dodaje tri tačke za prelivajući tekst */
  display: -webkit-box; /* Za višestruke linije teksta */
  -webkit-line-clamp: 3; /* Broj linija koje će se prikazati pre skraćivanja */
  -webkit-box-orient: vertical; /* Vertikalno orijentisanje kutije */
  margin-bottom: 10px; /* Razmak ispod teksta */
  font-size: 16px;
  color: rgb(86, 111, 143);
  line-height: 24px;
  font-weight: 400;
}

.cardFooter {
  display: flex; /* Koristi Flexbox za raspored sadržaja */
  /* justify-content: space-between; Raspoređuje sadržaj na dva kraja */
  align-items: center; /* Poravnava sadržaj vertikalno u sredini */
  margin-top: auto; /* Pomera footer na dno kartice */
  font-size: 0.9rem; /* Smanjuje veličinu fonta ako je potrebno */
  padding-top: 2rem;
}

.cardAuthor, .cardDate {
  margin: 1; /* Uklanja podrazumevane margine */
  font-size: 14px;
  font-weight: 500;
  color: rgb(152, 161, 172);
 
}

.verticalLine {
  height: 20px; /* Visina vertikalne linije */
  width: 1px; /* Širina vertikalne linije */
  background-color: #ccc; /* Boja vertikalne linije */
  margin: 0 10px; /* Razmak između linije i teksta */
}



.filteredPosts {
  
  margin: 0 auto; /* Centriranje komponente */
  background-color: #1D4E5B;
  border-radius: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.cardCategory{
  color: blue;
  padding-bottom: 1.5rem;
  font-weight: 600;
  size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .categoryNav {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    padding-left: 1rem;
    padding-right: 1rem;
   
  }

}

.loadMoreButton{
  text-align: center;
  
  
  
}

.loadMoreButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Ako želite malo razmaka od ostalih elemenata */
}

@media (max-width: 767px) {
  .categoryNav {
    display: flex;
    flex-direction: column; /* Kategorije se prikazuju jedna ispod druge */
    align-items: center;
  }

  .categoryNavButton {
    width: 100%; /* Kategorije zauzimaju celu širinu */
    margin-bottom: 10px; /* Razmak između dugmića */
  }

  .searchContainer {
    flex-direction: column; /* Prikazuje elemente jedan ispod drugog */
    align-items: center; /* Poravnava elemente na sredini */
  }

  .title {
    margin-bottom: 10px; /* Dodaje razmak između naslova i pretrage */
    text-align: center; /* Naslov je centriran */
  }

  .searchWrapper {
    width: 100%; /* Search input zauzima celu širinu */
    display: flex;
    justify-content: center; /* Centriranje search inputa */
  }
}