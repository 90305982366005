.demodiv{
    padding: 8rem 7rem 4rem;
    background-color: #0e3747;
    color:whitesmoke;
    max-width: 1500px; 
    margin: 0 auto;
}
.color{
    background-color: #0e3747;
}
.smalldiv{
    padding: 1.5rem;
    border-radius: 10px;
    margin:0rem 1rem;
}
.slika{
    width:350px;
     height:350px;
    
     margin-top: 4rem;
}

@media(max-width: 768px) {
    
    .slika{
        margin:3rem 0;
        padding: 0;
        width: 300px;
        height: 200px;
        align-items: center;
    }
    .demodiv{
        margin: 0;
        padding: 5rem 0;
        padding-left: 1rem;
        padding-right: 1rem;
        
    }
    .smalldiv{
        padding: 0 1rem;
        margin: 0;
    }

    
  }
@media(max-width: 768px) {
    
  
    

    .reverseOnSmallScreen {
        display: flex;
        flex-direction: column-reverse;
      }
  }

  