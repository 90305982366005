@media (min-width: 768px) {
    .postDetail{
   
        padding-top: 150px;
        line-height: normal;
       
        align-items: center;
    }
    .title{
        padding-bottom: 30px;
        font-family: 'Switzer', sans-serif;
        text-align: center;
        color: rgb(0,198,19);
    }
    
    .text{
       padding-bottom: 50px;
       text-align: left;
       padding-left: 100px;
       padding-right: 100px;
       
       font-family: 'Switzer', sans-serif;
    }
  }




@media (max-width: 768px) {
    .text{
        padding-bottom: 1rem;
        text-align: left;
        margin-left: 20px;
        margin-right: 20px;
     }

     .title{
        padding-top: 7rem;
        padding-bottom: 2rem;
        text-align: center;
        margin-left: 30px;
        margin-right: 30px;
        color: rgb(0,198,19);
    }
    
  }
@media (min-width: 1500px) {
    .text{
        padding-bottom: 50px;
        text-align: left;
        margin-left: 300px;
        margin-right: 300px;
     }

     .title{
        padding-bottom: 30px;
        text-align: center;
        color: rgb(0,198,19);
    }
    
  }