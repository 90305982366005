/* za veliki ekran */

.twoimage{
  max-width: 115%;
  height: auto;
}

@media (min-width: 768px) {
    .mobilni {
      padding-top: 100px;
      padding-bottom: 50px;
      max-width: 1500px; 
      margin: 0 auto;
  }
  .whyHero{
    padding-right:50px;
    padding-left: 100px;
    padding-top: 11rem;
  }
  .phoneHero{
    padding-bottom: 60px;
    max-width: 1500px; 
    margin: 0 auto;
  }
  .twopfones{
    margin-top: 20px;
    padding-top: 100px;
  }
  .matchdayImage{
    width: 50%;
    display: block;
    margin-bottom: 20px;
  }
  .cofpomoci{
    padding-right:100px;
    color:rgb(199, 198, 216);
    font-size: 20px;
    font-weight: bold;
  }
  .imageForHeroTwo{
    margin: 0px auto;
    margin-left: -35px;
  }
  .features{
    max-width: 1500px; 
      margin: 0 auto;
      padding-top: 7rem;
      padding-bottom: 7rem;
  }
    .whyHeroTitle{
      font-size: 40px;
      line-height: 50px;
      font-weight: 700;
    }
  }
  /* donji je za telefon */

  @media (max-width: 767px) {
    .mobilni {
      padding-top: 30px;
      padding-bottom: 10px;
  }
  .whyHero{
    padding-top: 80px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
    
    
  }
  .phoneHero{
    padding-bottom: 0px;
  }
  
  .matchdayImage{
    margin: 0 auto; 
    display: block;
    width: 70%;
  }
  .cofpomoci{
    text-align: center;
    color:rgb(199, 198, 216);
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }
  .whyHeroTitle{
    padding-top: 30px;
    text-align: left;
    padding-bottom: 20px;
    line-height: 40px;
  }
  .imageForHeroTwo{
    margin: 0 auto;
    margin-left: -35px;
  }
  .spancof{
    line-height:0%;
    
  }
  .features{
    
      padding-top: 2rem;
      padding-bottom: 2rem;
  }
  .brr{
    display: none;
  }
  
}

 
  

                
                
                

  