.arrow-button {
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .arrow-button img {
    width: 20px;
    height: 20px;
    filter: invert(1) sepia(1) saturate(5) hue-rotate(190deg);
  }

  .arrow-button-left{
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .arrow-button-left img{
    width: 20px;
    height: 20px;
    filter: invert(1) sepia(1) saturate(5) hue-rotate(190deg);
    transform: rotate(180deg);
  }
  .arrow-button:hover{
    background-color: #00c613;
    border:#ccc;
  }
  .arrow-button-left:hover{
    background-color: #00c613;
    border:#ccc;
}
.wholeDiv{
  padding: 1rem 2rem;
}

.headerText{
  font-size: 3.2rem;
  font-weight: bold;
}
.highlight-text {
  color: #5767aa; /* Example text color */
  background-color: #f0f0f0; /* Example background color */
  padding: 0.1rem 0.3rem; /* Padding around the text */
  margin: 0 0.3rem;
  border-radius: 8px; /* Rounded corners */
}
.smallDiv{
  text-align: end;
  margin: auto;
}
.largeDiv{
text-align: start;
}

@media (min-width: 300px) and (max-width: 600px) {
  .smallDiv{
    text-align: center;
    padding: auto;
  }
  .largeDiv{
    padding: 1rem 0;
  }
}