.header{
  padding: 1rem 0;
}
  .headerText {
    color: white;
  }
  .wholeDiv {
    background-color: #1D4E5B;
    border-radius: 1rem;
    overflow: hidden; /* Add this to handle overflow issues */
    margin:0rem  20rem;
    padding: 10rem 0;
  }
  .arrow-button {
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .arrow-button:hover{
    background-color: #00c613;
    border:#ccc;
  }
  .arrow-button-left:hover{
    background-color: #00c613;
    border:#ccc;
}
  .arrow-button img {
    width: 20px;
    height: 20px;
    filter: invert(1) sepia(1) saturate(5) hue-rotate(190deg);
  }

  .arrow-button-left{
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .arrow-button-left img{
    width: 20px;
    height: 20px;
    filter: invert(1) sepia(1) saturate(5) hue-rotate(190deg);
    transform: rotate(180deg);
  }
  @media (min-width: 600px) and (max-width: 1850px) {
    .wholeDiv {
        margin: 0  5rem;
    }
}
@media (min-width: 300px) and (max-width: 600px) {
  .wholeDiv {
      margin: 0  1rem;
  }
}

.smallDiv{
  text-align: end;
  margin: auto;
}
.largeDiv{
text-align: start;
}

@media (min-width: 300px) and (max-width: 600px) {
  .smallDiv{
    text-align: center;
    padding: auto;
  }
  .largeDiv{
    padding: 1rem 0;
  }
}