
.dugme{
    margin:2rem 0 1rem 0;
}
.rowcss{
color: whitesmoke;
padding: 10% 10%;
background-color:#0e3747;
text-align: left;
}
.secondheader{
    font-weight: 300;
    padding: 1rem 0;
    text-align: left;
    font-size: 1.2rem;
}
.image{
    width: 400px;
        height: 280px;
        border-radius:5%;
        margin-bottom:1rem;
}
.imagetext{
    margin:0;
     padding-top:0;
     color:#00c613;
      text-align:left;
}
.imagetextt{
    margin:0;
    padding-top:0;
    color:whitesmoke;
     text-align:left;
}
.buttonstyle{
    padding: 3.5rem 0rem;
    padding-bottom: 1rem;
    width: 100%;
}
.horizontalna{
    width:20%;
    color:#00c613;
    border-width:2px;
}
.buttoncontainer{
    margin: 0;
    padding: 0;
}
.res100{
    max-width: 1500px; 
    margin: 0 auto;
}
@media only screen and (max-width: 768px) {
    
    .image{
        width: 270px;
        height: 190px;
    }
    .mainheader{
        margin: 0;
        padding: 0;
        text-align: left;
    }
    .secondheader{
        text-align: left;
        padding: 0;
        margin: 0;
    }
    .dugme{
        margin: 1rem 2.8rem;
        text-align: center;
        align-items: center;
    }
    .rowcss{
        padding: 7rem 1rem;
        text-align: center;
        align-items: center;
    }
    .imagetext{
        text-align: left;
    }
    .imagetextt{
        text-align: left;
    }
    .buttonstyle{
        align-items: center;
        text-align: center;
        width: 100%;
    }
    .horizontalna{
        width: 100%;
    }
    .buttoncontainer{
        padding: 0.5rem 0;
    }
  }