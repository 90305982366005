
.textContainer {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
}

@media (min-width: 768px) {
  .textContainer {
    padding-left: 60px;
    padding-right: 10px;
    padding-top: 60px;
  }

  .res100{
    max-width: 1500px;
    margin: 0 auto;
  }

}

.image {
  max-width: 100%;
  height: auto;
}

.title {
  margin-bottom: 1rem;
}

.text {
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .textContainer {
    padding-top: 0;
    /* Postavite paddingTop na 0px samo na malim ekranima */
  }

  .image {
    margin-top: 0;
    /* Postavite marginTop na 0px za sliku na malim ekranima */
  }

  .reverseOnSmallScreen {
    display: flex;
    flex-direction: column-reverse;
  }
}

