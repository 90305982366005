.herosection {
    text-align: left;
}

.herorow{
    max-width: 1500px;
    margin: 0 auto;
    height: 600px;
    padding-top: 80px;

}


.res100{
    max-width: 1500px; 
    margin: 0 auto;
}
.herotext {
    margin: 0;
    padding: 12rem 20rem;
}

.wearecof {
    font-weight: bold;
    padding-top: 200px;
}

.valuestext {
    text-align: left;
    max-width: 1500px;
    margin: 0 auto;
    color: white;

}

.valuerow {
    padding: 1rem 4rem;
}

.keyvalues {

    background-color: #0e3747;
    text-align: center;
}

.ourstory {
    background-color: #1d4e5b;
    text-align: left;
    padding: 3rem;
    max-width: 1500px;
    margin: 0 auto;
}

.ourstorywraper {
    background-color: #1d4e5b;
    
    max-width: 1500px; 
    margin: 0 auto;
}

.teamwraper {
    background-color: #0e3747;
}

.storytitle {
    font-weight: bold;
    padding: 0;
    color: whitesmoke;
}

.flexContainer {
    display: flex;
  }
  

  

.storytext {
    margin-top: 5px;
    padding-bottom: 3rem;
}

.arrowimg {
    width: 8rem;
    padding-top: 3rem;
}


.arrowrow {
    padding: 2rem 0;
    margin: 0;
    text-align: end;
    padding-right: 4rem;
}

.arrowimgg {
    width: 4rem;
    margin-right: 3rem;

}

.imagerow {
    padding-top: 0rem;
}

.team {
    max-width: 1500px;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    padding: 4rem 4rem;
    background-color: #0e3747;
    color: whitesmoke;
}

.teamheader {
    padding-bottom: 0rem;
    text-align: left;
}

.teamrow {
    text-align: center;
    align-items: center;
}

.newsletter {
    background-color: #1d4e5b;
    color: whitesmoke;
    max-width: 1500px;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    padding: 6rem;
}

.newsheader {
    text-align: left;
}

.headertext {
    text-align: left;
}

.newsinputs {
    padding: 0rem 3rem;
}

.headercol {
    padding: 0 1rem;
}

.inputstext {
    text-align: left;
}

.valuecomponent {
    align-items: center;
    text-align: center;
    background-color: #0e3747;
    
}

.timelineseparator{
    border-bottom: 2px solid #00c613;
    width: 10%;
    padding-top: 0.25rem;
    margin-bottom: 0.75rem;
}

.ourteamseparator{
    border-bottom: 2px solid #00c613;
    width: 8%;
    padding-top: 0.25rem;
    margin-bottom: 3.75rem;
}

.cofimage {
   
    height: 400px;
}



@media only screen and (max-width: 768px) {

    .herotext {
        font-size: 3rem;
        margin: 0;
        padding: 10rem 4rem;
    }

    .valuecomponent {
        align-items: center;
        text-align: center;
        background-color: #0e3747;
    }

    .wearecof {
        padding-top: 20px;
    }

    .cofimage {
        width: 100%;
        
    }

    .newsinputs {
        padding: 0rem 0.5rem;
    }

    .valuerow {
        align-items: center;
        text-align: center;
        padding: 1rem 2rem;
        margin: 0;
    }

    .ourstory {
        align-items: center;
        text-align: left;
        padding: 1rem 1rem;
        margin: 0;
    }

    .timelineseparator{
        width: 50%;
    }

    .ourteamseparator{
        width: 50%;
    }

    .storytitle {
        padding: 1rem 0;
        font-size: 2rem;
    }

    .storyfirstrow {
        padding: 0 1rem;
        display: flex;
        flex-direction: column-reverse;
    }

    .arrowimg {
        transform: rotate(90deg);
        padding: 3rem 0;
    }
    .arrowimgg {
        width: 8rem;
        margin: auto;
    
    }
    .arrowrow{
        padding: 1rem 0rem;
        margin: 0;
        text-align: center;
        padding-right: 0;
    }
    .arrowimagecontainer {
        text-align: center;
    }

    .imagerow {
        transform: rotate(180deg);
        padding: 0.5rem 0;
        text-align: center;
    }

    .newsletter {
        padding: 3rem 1rem;
        margin: 0;
        align-items: center;
        text-align: left;
    }

    .newsheader {
        font-size: 2rem;
    }

    .valuestext {
        text-align: center;
        max-width: 1500px;
        margin: 0 auto;
        padding-top: 20px;
        font-size: clamp(42px, 6vw, 84px);
    }
    
}



.keyvalues {
    padding-top: 10px;
    padding-bottom: 100px;
    text-align: center;
    max-width: 1500px;
    margin: 0 auto;
}

@media only screen and (min-width: 770px) and (max-width: 1480px) {
    .arrowimg {
        width: 4rem;
        padding-top: 5rem;
    }
    .arrowimgg {
        margin-right: 0rem;
    
    }
}


@media (min-width: 768px) {
    .ourstorywraper {
        background-color: #1d4e5b;
        padding-left: 5rem;
        padding-right: 5rem;
        max-width: 1500px; 
        margin: 0 auto;
    }
    .paddin{
        padding-left: 5rem;
        padding-right: 5rem;
        max-width: 1500px; 
        margin: 0 auto;
    }

    .valuecomponent {
        align-items: center;
        text-align: center;
        background-color: #0e3747;
        padding-left: 5rem;
        padding-right: 5rem;
        max-width: 1500px; 
        margin: 0 auto;
    }
    .teamwraper {
        background-color: #0e3747;
        padding-left: 5rem;
        padding-right: 5rem;
        max-width: 1500px; 
        margin: 0 auto;
    }
    

    
    }


    .colimg{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    @media (max-width: 767px){
        .cofimage {
            width: auto;
            height: 80px;
            text-align: center;
        }
        .colimg{
            justify-content: center;
            align-items: center;
        }

        .herorow{
            height: auto;
        }
        .tezimo{
            padding-top: 3rem;
            padding-bottom: 0rem;
            margin-left: 5px;
            margin-bottom: -10px;
        }
    }
    