.mainRow{
    padding:0rem 0rem;
    margin: 0 3rem;
    padding-bottom: 5rem;
}

.firstDiv{

    background-color: rgb(39,38,43);
    border-radius: 1rem;
    height: 600px;
    padding: 3rem;
    margin: 0 1rem;
}
.secondDiv{
    height: 600px;
    background-color: whitesmoke;
    border-radius: 1rem;
    padding: 3rem;
    margin: 0 1rem;
    text-align: left;
}
.firstDiv {
    position: relative; /* Set relative positioning to contain the absolute positioning of dots */
  }
  
  .carousel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .custom-controls {
    margin-top: auto; /* Pushes the controls to the bottom */
    padding: 0 1rem;
  }
  
  .custom-dots-container {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Adjust as needed */
  }
  
  .custom-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .custom-dot.active {
    background-color: #333; /* Color for the active dot */
  }
  
  .arrow-button-right-testemonials {
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .arrow-button-right-testemonials img {
    width: 15px;
    height: 15px;
    filter: invert(1) sepia(1) saturate(5) hue-rotate(190deg);
  }

  .arrow-button-left-testemonials{
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .arrow-button-left-testemonials img{
    width: 15px;
    height: 15px;
    filter: invert(1) sepia(1) saturate(5) hue-rotate(190deg);
    transform: rotate(180deg);
  }
  .arrow-button-right-testemonials:hover{
    background-color: #00c613;
    border:#ccc;
  }
  .arrow-button-left-testemonials:hover{
    background-color: #00c613;
    border:#ccc;
}
  
  .highlight-part {
    color: black;
    background: linear-gradient(to right, #adf77f, #7ef435); /* Example background color */
    padding: 0.1rem 0.3rem; /* Padding around the text */
    border-radius: 8px; /* Rounded corners */
  }
@media (max-width: 767px) {
    .mainRow{
        padding: 1rem;
        margin: 0;
    }
    .firstDiv{
        margin: 0;
        margin-bottom: 2rem;
        padding:2rem 1rem;
    }
    .secondDiv{
        margin: 0;
        padding: 2rem 1rem;
        text-align: center;
    }
    .custom-controls{
        padding: 0 2rem;
    }
  }
  @media (min-width: 1800px) and (max-width: 3000px) {
    .mainRow{
        margin: 0 18rem;
        border: none;
    }
    .secondDiv{
    padding: 4rem 5rem;
        margin: auto;
    }
    .firstDiv{
        padding: 4rem 5rem;
        margin: auto;
    }
  }
