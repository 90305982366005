.textContainer {
    padding-left: 20px; /* Prilagodite širinu po potrebi */
    padding-right: 20px; /* Prilagodite širinu po potrebi */
    padding-top: 0px;
  }
  
  @media (min-width: 768px) {
    .textContainer {
      padding-left: 10px; /* Prilagodite širinu po potrebi */
      padding-right: 60px; /* Prilagodite širinu po potrebi */
      padding-top: 60px;
    }

    .res100{
      max-width: 1500px;
      margin: 0 auto;
    }
    
  }
  
  .image {
    max-width: 100%;
    height: auto;
  }

  .twoimage{
    max-width: 115%;
    height: auto;
  }
  
  .title {
    margin-bottom: 1rem;
  }
  
  .text {
    margin-bottom: 1rem;
  }
  
  @media (max-width: 767px) {
    .textContainer {
        padding-top: 0; /* Postavite paddingTop na 0px samo na malim ekranima */
    }

    .image {
        margin-top: 0; /* Postavite marginTop na 0px za sliku na malim ekranima */
    }
    .newClassName {
      padding-top: 10px;
      padding-bottom: 10px;
    }
}

