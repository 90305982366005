
.komponenta{
    background-color: #0e3747;
    max-width: 1500px; 
    margin: 0 auto;
}
.red{
    background-color: #0e3747;
    color: whitesmoke;
    padding:7% 9%;
}
.slika{
    height: 400px;
    width: 500px;
    border-radius: 5%;
}
.dugme{
    margin-top: 2rem;
    padding: 0.5rem;
}
.secondheader{
    font-weight: 300;
    padding: 0.5rem 0;
    text-align: left;
}
.horizontalna{
    width:100%;
    color:#00c613;
     border-width:2px;
}
.questionparagraph{
    font-weight: 300;
    padding: 0.3rem 0;
    padding-right: 3rem;
}
.tekstovi{
    padding-top: 4rem;
}
.divdugme{
    padding: 3rem 0 0rem;
}
.novodugme{
    padding: 0.3rem 1rem;
    margin: 0;
}
@media only screen and (max-width: 768px) {
    .red{
        padding: 2rem 1rem;
        margin: 0;
    }
    .slika{
        width: 300px;
        height: 250px;
    }
    .tekstovi{
        padding-top: 1rem;
        text-align: left;
        order: 1;
    }
    .slikacol{
        order:2 ;
        text-align: center;
    }
    .dugme{
        align-items: center;
        text-align: center;
    }
    .divdugme{
        
        padding: 2rem 0 0;
    }
    .secondheader{
        text-align: left;
    }
    .horizontalna{
        width: 100%;
    }
    
  }