/* Solutions.module.css */

  

@media (min-width: 768px) {
    .mobilni {
      padding-top: 50px;
      padding-bottom: 50px;
  }
  .logo{
    width: 7%;
    padding-bottom: 20px;
    height: auto;
  }
  .solutionheader{
    text-align: center;
    padding-bottom: 0rem;
    padding-top: 2rem;
    max-width: 1500px; 
    margin: 0 auto;
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .btnpad{
    padding-left: 10px;
    padding-right: 10px;
  }
  }
  
  @media (max-width: 767px) {
    .mobilni {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .logo{
    width: 20%;
    height: auto;
  }
  .solutionheader{
    text-align: center;
    padding-bottom: 0rem;
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    
  }
  .btnpad{
    padding-left: 5px;
    padding-right: 5px;
  }
  .unapredi{
    text-align: left;
    padding-left: 10px;
    padding-right: 5px;
    }
  
    .pojednostavi{
      text-align: left;
    }
    .upoznaj{
      text-align: left;
    }
    .autor{
      text-align: left;
      margin-left: 10px;
    }
    .isprobajte{
      padding-top: 10px;
      text-align: left;
      margin-left: 1rem;
      
    }
  }
  
  
  
  /* styles.module.css */
  
  .container {
    width: 100%;
    height:auto;
    position: relative;
    z-index: -1;
  }
  
  .upper {
    width: 100%;
    height: 35%;
    background-color: rgb(14,55,71);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2; /* Postavite z-index na veću vrednost od 0 */
  }
  
  .lower {
    width: 100%;
    height: 65%;
    background-color: #1d4e5b;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -2; /* Postavite z-index na manju vrednost od 1 */
  }
  
  
  .unapredi{
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  }
  
  .pojednostavi{
    padding:0 1rem;
    font-size: 42px;
    padding-top: 10px;
    padding-bottom: 30px;
    font-weight: 600;
    line-height: 60px;
  }
  
  .upoznaj{
    color: rgb(0,198,19);
    font-size: 17px;
    padding: 0 1rem;
    font-weight: 500;
    line-height: 26px;
  }
  .isprobajte{
    padding-top: 10px;
  }
  

  